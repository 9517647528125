html {
	overflow-y: scroll;
}

.container {
	max-width: 1325px;
}

a {
	cursor: pointer;
	color: inherit;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

.centered {
	text-align: center;
}

.unlockable {
	white-space: pre;
}

h1 {
	font-family: "Luckiest Guy", cursive;
	color: #22fc6f;
	text-shadow: 1px 1px black, -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, -4px 4px 0 #1f1b4e;
}

.overlay {
	position: fixed;
	pointer-events: none;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.overlay>* {
	pointer-events: all;
}

.center-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.grow {
	flex: 1 1 0;
}

.interactable {
	cursor: pointer;
}

.hoverable {
	cursor: help;
}

.disable-user-select {
	user-select: none;
}

.disable-user-select * {
	user-select: none;
}