html {
  overflow-y: scroll;
}

.container {
  max-width: 1325px;
}

a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.centered {
  text-align: center;
}

.unlockable {
  white-space: pre;
}

h1 {
  color: #22fc6f;
  text-shadow: 1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000, -4px 4px #1f1b4e;
  font-family: Luckiest Guy, cursive;
}

.overlay {
  pointer-events: none;
  position: fixed;
  inset: 0;
}

.overlay > * {
  pointer-events: all;
}

.center-content {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.grow {
  flex: 1;
}

.interactable {
  cursor: pointer;
}

.hoverable {
  cursor: help;
}

.disable-user-select, .disable-user-select * {
  user-select: none;
}

/*# sourceMappingURL=index.e5d1682b.css.map */
